import { where } from 'firebase/firestore';
import { customAlphabet } from 'nanoid';
import Router from 'next/router';
import {
  compose,
  contains,
  filter,
  isEmpty,
  isNil,
  map,
  reject,
  reverse,
  uniq,
  values,
} from 'ramda';
import { find } from 'remeda';
import { Weddings } from '@bridebook/models';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { Suppliers } from '@bridebook/models/source/models/Weddings/Suppliers';
import {
  IAddress,
  ISupplier as IDBWeddingSupplier,
} from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { enrichSuppliers } from '@bridebook/toolbox/src/enrich-suppliers/enrich-suppliers';
import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { getSuppliersData } from '@bridebook/toolbox/src/inbox/api/utils/get-suppliers-data';
import { mapSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { IUISupplier, IWeddingSupplier, Slug } from '@bridebook/toolbox/src/types';
import { formatUrl } from 'app-shared/lib/supplier/utils/formatUrl';
import { IUserEnquiries, TEnquiryDates } from 'lib/enquiries/types';
import { getI18n } from 'lib/i18n/getI18n';
import { IShortlistedBlockType, TShortlistTab } from 'lib/shortlist/types';
import { IShortlistedBlockCustomType, SupplierCategory } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { filterByType, sortByCreatedAt, sortByUpdatedAt } from 'lib/utils';
import { isString } from 'lib/validationUtils';
import { CustomSupplierFormType } from './reducer';

export const createCustomSupplierObj = (
  fields: CustomSupplierFormType['fields'],
): Partial<IShortlistedBlockType> => {
  /**
   * Crockford base-32 charset.
   *
   * Excludes I, L, O and U to prevent confusion and unwanted puns and obscenity.
   * Note: Don't use hyphens (`-`) for the supplier ID, as they have special meaning for our URL parser.
   */
  const id = `FS${customAlphabet('0123456789ABCDEFGHJKMNPQRSTVWXYZ')(10)}`;
  return {
    id,
    booked: false,
    notes: '',
    createdAt: Date.now(),
    checked: true,
    customSupplier: true,
    professional: false,
    ...fields,
    type: fields.slug as Slug,
  };
};

type GetSeeYourShortlisted = (o: { name: string; market: Market }) => string;

export const getSeeYourShortlisted: GetSeeYourShortlisted = ({ name, market }) => {
  const i18n = getI18n();

  switch (name) {
    case 'venues':
    case 'venue':
      return i18n.t('shortlist:category.seeShortlisted.venue');
    case 'photographers':
    case 'photo':
      return i18n.t('shortlist:category.seeShortlisted.photographer');
    case 'florists':
    case 'florist':
      return i18n.t('shortlist:category.seeShortlisted.florist');
    case 'videographers':
    case 'video':
      return i18n.t('shortlist:category.seeShortlisted.videographer');
    case 'catering':
      return i18n.t('shortlist:category.seeShortlisted.caterer');
    case 'wedding dress and accessories':
    case 'dress':
      return i18n.t('shortlist:category.seeShortlisted.dressmaker');
    case 'cake makers':
    case 'cakes':
      return i18n.t('shortlist:category.seeShortlisted.cakes');
    case 'beauty, hair and make-up':
    case 'beauty':
      return i18n.t('shortlist:category.seeShortlisted.hairBeauty');
    case 'wedding rings and jewellery':
    case 'jewellery':
      return i18n.t('shortlist:category.seeShortlisted.jewellers');
    case 'decoration and hire':
    case 'decoration':
      return i18n.t('shortlist:category.seeShortlisted.decorHire');
    case 'planners, toastmasters and celebrants':
    case 'planners':
      return i18n.t('shortlist:category.seeShortlisted.planners');
    case 'transport':
      return i18n.t('shortlist:category.seeShortlisted.transport');
    case 'music':
      return i18n.t('shortlist:category.seeShortlisted.music');
    case 'entertainment':
      return i18n.t('shortlist:category.seeShortlisted.entertainment');
    case 'menswear':
      return i18n.t('shortlist:category.seeShortlisted.menswear');
    case 'stationery':
      return i18n.t('shortlist:category.seeShortlisted.stationery');
    case 'marquee hire':
    case 'marquee':
      return i18n.t('shortlist:category.seeShortlisted.marquees');
    case '':
      return i18n.t('shortlist:category.seeShortlisted.suppliers');
    default:
      return i18n.t('shortlist:category.seeShortlisted.default', {
        category: categoryNameMapping({ name, plural: true, market }),
      });
  }
};

type GetCategoryPossesive = (o: { name: string; market: Market }) => string;

export const getCategoryPossesive: GetCategoryPossesive = ({ name, market }) => {
  const i18n = getI18n();

  switch (name) {
    case 'venues':
    case 'venue':
      return i18n.t('shortlist:category.possesive.venue');
    case 'photographers':
    case 'photo':
      return i18n.t('shortlist:category.possesive.photographer');
    case 'florists':
    case 'florist':
      return i18n.t('shortlist:category.possesive.florist');
    case 'videographers':
    case 'video':
      return i18n.t('shortlist:category.possesive.videographer');
    case 'catering':
      return i18n.t('shortlist:category.possesive.caterer');
    case 'wedding dress and accessories':
    case 'dress':
      return i18n.t('shortlist:category.possesive.dressmaker');
    case 'cake makers':
    case 'cakes':
      return i18n.t('shortlist:category.possesive.cakes');
    case 'beauty, hair and make-up':
    case 'beauty':
      return i18n.t('shortlist:category.possesive.hairBeauty');
    case 'wedding rings and jewellery':
    case 'jewellery':
      return i18n.t('shortlist:category.possesive.jewellers');
    case 'decoration and hire':
    case 'decoration':
      return i18n.t('shortlist:category.possesive.decorHire');
    case 'planners, toastmasters and celebrants':
    case 'planners':
      return i18n.t('shortlist:category.possesive.planners');
    case 'transport':
      return i18n.t('shortlist:category.possesive.transport');
    case 'music':
      return i18n.t('shortlist:category.possesive.music');
    case 'entertainment':
      return i18n.t('shortlist:category.possesive.entertainment');
    case 'menswear':
      return i18n.t('shortlist:category.possesive.menswear');
    case 'stationery':
      return i18n.t('shortlist:category.possesive.stationery');
    case 'marquee hire':
    case 'marquee':
      return i18n.t('shortlist:category.possesive.marquees');
    case '':
      return i18n.t('shortlist:category.possesive.suppliers');
    default:
      return i18n.t('shortlist:category.possesive.default', {
        category: categoryNameMapping({ name, plural: true, market }),
      });
  }
};

type CategoryNameMapping = (o: {
  name: string;
  plural?: boolean;
  lowercase?: boolean;
  market: Market;
}) => string;

/**
 * TODO: Check whether the parameter `name` is every provided in plural form.
 * TODO: [i18n] [bb-global] Use `Market.country` instead of `locale`.
 * @deprecated TODO: [i18n] [bb-global] This is not using proper locale *reverse* plural forms.
 */
export const categoryNameMapping: CategoryNameMapping = ({ name, plural, lowercase, market }) => {
  const getCategoryName = () => {
    const i18n = getI18n();
    const count = plural ? 2 : 1;

    if (!name) {
      // eslint-disable-next-line no-console
      console.warn(
        'Category name in shortlist categoryNameMapping expected to be provided, received: ' + name,
      );
      return name;
    }

    switch (name.toLowerCase()) {
      case 'venues':
      case 'venue':
        return i18n.t('shortlistCategories:category.venue', {
          count,
        });
      case 'photographers':
      case 'photo':
        return i18n.t('shortlistCategories:category.photographer', {
          count,
        });
      case 'florists':
      case 'florist':
        return i18n.t('shortlistCategories:category.florist', {
          count,
        });
      case 'videographers':
      case 'video':
        return i18n.t('shortlistCategories:category.videographer', {
          count,
        });
      case 'catering':
        return i18n.t('shortlistCategories:category.caterer', {
          count,
        });
      case 'wedding dress and accessories':
      case 'dress':
        return i18n.t('shortlistCategories:category.dressmaker', {
          count,
        });
      case 'cake makers':
      case 'cakes':
        return i18n.t('shortlistCategories:category.cakeMaker', {
          count,
        });
      case 'beauty, hair and make-up':
      case 'beauty':
        return i18n.t('shortlistCategories:category.hairBeauty', {
          count,
        });
      case 'wedding rings and jewellery':
      case 'jewellery':
        return i18n.t('shortlistCategories:category.jewellery', {
          count,
        });
      case 'decoration and hire':
      case 'decoration':
        return i18n.t('shortlistCategories:category.decorHire', {
          count,
        });
      case 'planners, toastmasters and celebrants':
      case 'planners':
        return i18n.t('shortlistCategories:category.planners', {
          count,
        });
      case 'transport':
        return i18n.t('shortlistCategories:category.transport', {
          count,
        });
      case 'music':
        return i18n.t('shortlistCategories:category.music', {
          count,
        });
      case 'entertainment':
        return i18n.t('shortlistCategories:category.entertainment', {
          count,
        });
      case 'menswear':
        return i18n.t('shortlistCategories:category.menswear', {
          count,
        });
      case 'stationery':
        return i18n.t('shortlistCategories:category.stationery', {
          count,
        });
      case 'marquee hire':
      case 'marquee':
        return i18n.t('shortlistCategories:category.marquee', {
          count,
        });
      case '':
        return i18n.t('shortlistCategories:category.supplier', {
          count,
        });
      default:
        // eslint-disable-next-line no-console
        console.warn('Missing category in shortlist categoryNameMapping, please add it: ' + name);
        return name;
    }
  };

  const categoryName = getCategoryName();

  // TODO: [i18n] [bb-global] Should probably use feature flag or somethign similar
  return lowercase || market.country === CountryCodes.DE // German is always uppercase
    ? categoryName
    : categoryName
        .split(' ')
        .map((l) => {
          // Exceptions for capitalisation
          if (market.country === CountryCodes.FR && ['et', 'de'].includes(l)) {
            return l;
          }
          return l[0]?.toUpperCase() + (l?.slice(1) || '');
        })
        .join(' ');
};

export const filterListBySlug = <T extends { type: Slug }>(list: T[], type: Slug): T[] =>
  filterByType<T>(list, type);

export const bookedSupplierList = <T extends { createdAt?: number; booked: boolean }>(
  list: T[],
): T[] => reverse(sortByUpdatedAt(list.filter((item) => item.booked)));

export const shortlistedSupplierList = <T extends { createdAt: number; booked?: boolean }>(
  list: T[],
): T[] => reverse(sortByCreatedAt<T>(list.filter((item) => !item.booked)));

// get a list of booked shortlist categories
export const getActiveShortlistCategories = (
  list: Record<string, IWeddingSupplier>,
  supplierCategories: SupplierCategory[],
): SupplierCategory[] => {
  if (list && supplierCategories) {
    // get a list on unique booked categories
    const activeCategories = compose<Record<string, IWeddingSupplier>, any, any, Slug[], Slug[]>(
      uniq,
      values,
      map((item: IWeddingSupplier) => item.type),
      filter((item: IWeddingSupplier) => item.booked),
    )(list);

    // return a filtered list of categories that contain booked suppliers
    return filter((item) => contains(item.value, activeCategories), supplierCategories);
  }

  return [];
};

export interface IExtractedFormFields {
  name: string;
  phone: string;
  website: string;
  address: string;
}

export const extractFormFieldsFromPlace = (
  place: google.maps.places.PlaceResult,
): IExtractedFormFields => {
  const { name, international_phone_number, website, formatted_address } = place;

  return {
    name: name || '',
    phone: (international_phone_number || '').replace(/\+/g, '').replace(/ /g, ''),
    website: website || '',
    address: formatted_address || '',
  };
};

const mapSupplierAddress = (supplier: IUISupplier): IWeddingSupplier['address'] => {
  if (
    !supplier.town &&
    !supplier.country &&
    !supplier.name &&
    !supplier.postcode &&
    !supplier.address
  ) {
    return Suppliers.new('address');
  }

  const adminArea: string[] | null = supplier.town || supplier.country ? [] : null;

  if (adminArea && supplier.town) adminArea.push(supplier.town);
  if (adminArea && supplier.country) adminArea.push(supplier.country);

  const address: IAddress = {
    adminArea: adminArea ?? undefined,
    city: supplier.town ?? undefined,
    name: supplier.town ?? undefined,
    // @ts-expect-error TODO: this should be always CountryCodes
    country: supplier.customSupplier ? supplier.country : CountryCodes.GB,
    postalCode: supplier.postcode ?? undefined,
    street:
      supplier.address && isString(supplier.address) ? supplier.address.split(',') : undefined,
  };

  // TODO: Other address properties once we fix location mapping

  // postalCode: supplier.postcode || supplierEntity.FieldValue.delete(),
  // street: supplier.customSupplier ? supplier.address.split(',') : [],

  return Suppliers.new('address', reject(isNil, address));
};

const mapElasticSupplierContacts = (supplier: IUISupplier): IWeddingSupplier['contacts'] => {
  if (
    !(supplier.email && typeof supplier.email === 'string') &&
    !supplier.website &&
    !supplier.phone
  ) {
    return Suppliers.new('contacts');
  }

  const contacts = {
    email: supplier.email && typeof supplier.email === 'string' ? supplier.email : null,
    website: supplier.website ? formatUrl(supplier.website) : null,
    phone: supplier.phone || null,
  };

  return Suppliers.new('contacts', reject(isNil, contacts));
};

const mapSupplierContacts = (supplier: ISupplier): IWeddingSupplier['contacts'] => {
  if (
    !(typeof supplier.contacts?.email === 'string') &&
    !supplier.contacts?.website &&
    !supplier.contacts?.phone
  ) {
    return Suppliers.new('contacts');
  }

  const contacts = {
    email: typeof supplier.contacts?.email === 'string' ? supplier.contacts?.email : null,
    website: supplier.contacts?.website ? formatUrl(supplier.contacts?.website) : null,
    phone: supplier.contacts?.phone || null,
  };

  return Suppliers.new('contacts', reject(isNil, contacts));
};

//FIXME: to clarify where in new schema - all ts-expect-error needs to be fixed when refactored
const mapSupplierToShortlist = (supplier: ISupplier): IWeddingSupplier => ({
  address: {
    ...supplier.address,
    //@ts-expect-error
    adminArea: values(supplier.address?.adminArea),
  },
  contacts: mapSupplierContacts(supplier),
  //@ts-expect-error
  booked: !!supplier.booked,
  //@ts-expect-error TODO: cover on enquiries refactoring
  enquired: typeof supplier.enquired === 'boolean' ? supplier.enquired : false,
  id: supplier.id,
  name: supplier.name || '',
  type: supplier.type?.[0] || '',
  price: 0,
  //@ts-expect-error
  professional: typeof supplier.professional === 'boolean' ? supplier.professional : true,
  //@ts-expect-error
  customSupplier: !!supplier.customSupplier,
  //@ts-expect-error
  shortlisted: typeof supplier.shortlisted === 'boolean' ? supplier.shortlisted : true,
  //@ts-expect-error
  ignored: typeof supplier.ignored === 'boolean' ? supplier.ignored : false,
  //@ts-expect-error
  visitDate: supplier.visitDate || '',
});

const mapElasticToShortlist = (supplier: IUISupplier): IWeddingSupplier => ({
  address: mapSupplierAddress(supplier),
  contacts: mapElasticSupplierContacts(supplier),
  booked: !!supplier.booked,
  enquired: typeof supplier.enquired === 'boolean' ? supplier.enquired : false,
  id: supplier.id,
  name: supplier.name || '',
  type: supplier?.type || '',
  notes: supplier.notes || '',
  price: supplier.price || 0,
  professional: typeof supplier.professional === 'boolean' ? supplier.professional : true,
  customSupplier: !!supplier.customSupplier,
  shortlisted: typeof supplier.shortlisted === 'boolean' ? supplier.shortlisted : true,
  ignored: typeof supplier.ignored === 'boolean' ? supplier.ignored : false,
  visitDate: supplier.visitDate || '',
});

// TODO: refactor
export const buildShortlistSupplier = (supplier: IUISupplier | ISupplier): IDBWeddingSupplier => {
  const shortlisted: IWeddingSupplier =
    Array.isArray(supplier.type) && supplier.type?.[0]
      ? mapSupplierToShortlist(supplier as ISupplier)
      : mapElasticToShortlist(supplier as IUISupplier);

  if (isEmpty(shortlisted.address)) {
    delete shortlisted.address;
  }

  return Suppliers.new('_', shortlisted);
};

const mapVenueProps = (details: ISupplier['typeDetails']['venue']) => ({
  //  Venue fields
  accommodationRooms: details?.accommodation?.rooms,
  // style
  affordable: details?.style?.includes?.('affordable'),
  alternative: details?.style?.includes?.('alternative'),
  asian: details?.style?.includes?.('asian'),
  beach: details?.style?.includes?.('beach'),
  classic: details?.style?.includes?.('classic'),
  formal: details?.style?.includes?.('formal'),
  historic: details?.style?.includes?.('historic'),
  greatViews: details?.style?.includes?.('greatViews'),
  intimate: details?.style?.includes?.('intimate'),
  blankCanvas: details?.style?.includes?.('blankCanvas'),
  casual: details?.style?.includes?.('casual'),
  lovelyGrounds: details?.style?.includes?.('lovelyGrounds'),
  luxury: details?.style?.includes?.('luxury'),
  modern: details?.style?.includes?.('modern'),
  outdoor: details?.style?.includes?.('outdoor'),
  romantic: details?.style?.includes?.('romantic'),
  rustic: details?.style?.includes?.('rustic'),
  stylish: details?.style?.includes?.('stylish'),
  unique: details?.style?.includes?.('unique'),
  unusual: details?.style?.includes?.('unusual'),
  urban: details?.style?.includes?.('urban'),
  // type
  barn: details?.type?.includes?.('barn'),
  castle: details?.type?.includes?.('castle'),
  cityHotelCityVenue: details?.type?.includes?.('cityHotelCityVenue'),
  conferenceCentre: details?.type?.includes?.('conferenceCentre'),
  countryHouseManorHouse: details?.type?.includes?.('countryHouseManorHouse'),
  cruiseBoatYacht: details?.type?.includes?.('cruiseBoatYacht'),
  gardenOutdoor: details?.type?.includes?.('gardenOutdoor'),
  golfCourse: details?.type?.includes?.('golfCourse'),
  hotel: details?.type?.includes?.('hotel'),
  museumAttraction: details?.type?.includes?.('museumAttraction'),
  placeOfWorship: details?.type?.includes?.('placeOfWorship'),
  pubRestaurant: details?.type?.includes?.('pubRestaurant'),
  resort: details?.type?.includes?.('resort'),
  rooftop: details?.type?.includes?.('rooftop'),
  sportingVenueStadium: details?.type?.includes?.('sportingVenueStadium'),
  statelyHome: details?.type?.includes?.('statelyHome'),
  townHallRegistryOffice: details?.type?.includes?.('townHallRegistryOffice'),
  uniqueVenueType: details?.type?.includes?.('uniqueVenueType'),
  warehouseFactory: details?.type?.includes?.('warehouseFactory'),
  winery: details?.type?.includes?.('winery'),
  // bbDiscountExpiryDate: '',
  // coupleBudgetAvg: '',
  // coupleBudgetMin: '',
  // hasBridebookOffer: '',
  // hasQualityPricing: '',
  // latestSpecialOfferDate: '',
  // latestWeddingFairDate: '',
  // pricePerHeadMax: '',
  // pricePerHeadMin: '',
  // venueHirePriceMax: '',
  // venueHirePriceMin: '',
  // videoToursCount: '',
  // tier: '',
  // totalInclusivePriceMax: '',
  // totalInclusivePriceMin: '',
});

export const buildUISupplier = (supplier: ISupplier): IUISupplier => {
  const details = supplier.typeDetails?.venue;

  return {
    id: supplier.id,
    type: supplier.type[0],
    // bestOfBritain: boolean; is part of admin
    country: supplier.address?.adminArea?.[1] || supplier.address?.country || '', // consider renaming to adminArea1
    countryCode: supplier.l10n?.country || '',
    county: supplier.address?.adminArea?.[0] || '',
    currency: supplier.l10n?.currency || '',
    description: supplier?.description?.short || '',
    detailedDescription: supplier?.description?.long || '',
    email: Boolean(supplier.contacts?.email),
    lat: supplier?.coordinates?.latitude,
    lng: supplier?.coordinates?.longitude,
    name: supplier.name,
    // photos: '',
    // profileScore: '',
    publicId: supplier.publicId,
    // reviewsCount: '',
    // reviewsStars: '',
    seoUrlSlug: supplier.seo?.urlSlug,
    statusMessage: supplier.status?.message,
    // thumbnail: '',
    town: supplier.address?.city,
    ...(details ? mapVenueProps(details) : {}),
  };
};

export const mapSlug = (data: Record<string, IDBWeddingSupplier>) =>
  values<Record<string, IDBWeddingSupplier>, string>(data).map(
    (item: IDBWeddingSupplier) =>
      ({
        ...item,
        slug: item.type,
      } as IDBWeddingSupplier),
  );

const batchToRecord = (data: any[], shortlisted?: IWeddingSupplier[]) => {
  const result = {} as Record<string, any>;

  const getVisitDate = (id: string) =>
    shortlisted ? find(shortlisted, (item) => item.id === id)?.visitDate : '';

  data.forEach((item) => {
    const visitDate = getVisitDate(item.id);
    result[item.id] = { ...item, visitDate };
  });

  return result;
};

export const mapCustomShortlistSupplier = (
  supplier: IWeddingSupplier,
): IShortlistedBlockCustomType => ({
  // @ts-ignore FIXME
  address: supplier.address,
  customSupplier: supplier.customSupplier,
  email: supplier.contacts?.email || '',
  phone: supplier.contacts?.phone || '',
  professional: supplier.professional,
  id: supplier.id,
  // @ts-ignore FIXME
  name: supplier.name,
  type: supplier.type,
  price: supplier.price,
  notes: supplier.notes || '',
  booked: supplier.booked,
  updatedAt: supplier.updatedAt,
});

export const fetchWeddingSuppliers = async (weddingId: string): Promise<IWeddingSupplier[]> => {
  const suppliers = Weddings._.getById(weddingId).Suppliers;
  const weddingSuppliers: IWeddingSupplier[] = await suppliers
    .query()
    .get()
    .then(mapSlug)
    .then((data) => mapSerializeTimestamps<IDBWeddingSupplier>(data))
    .then(sortByCreatedAt);

  if (!values(weddingSuppliers).length) {
    return [];
  }

  return weddingSuppliers;
};

export const enrichWeddingSuppliers = async (weddingSuppliers: IWeddingSupplier[]) => {
  const nonCustom = values<IWeddingSupplier[], number>(weddingSuppliers).filter(
    (s) => !s.customSupplier,
  );
  const custom = values<IWeddingSupplier[], number>(weddingSuppliers)
    .filter((s) => s.customSupplier)
    .map(mapCustomShortlistSupplier);

  return enrichSuppliers(nonCustom).then((list) => {
    const idToIndexNonCustom = new Map(nonCustom.map((s, idx) => [s.id, idx]));
    return batchToRecord(
      [
        ...list.map((item) => {
          const index = idToIndexNonCustom.get(item.id);
          return {
            ...item,
            // @ts-expect-error
            updatedAt: typeof index === 'number' ? nonCustom[index].updatedAt?.seconds : undefined,
          };
        }),
        ...custom,
      ],
      weddingSuppliers,
    );
  });
};

export const fetchSuppliersById = async (weddingId: string, supplierIds: string[]) => {
  const suppliers = Weddings._.getById(weddingId).Suppliers;

  const baseEnquired: IWeddingSupplier[] = await suppliers
    .query([where('id', 'in', supplierIds)])
    .get()
    .then(mapSlug)
    .then((data) => mapSerializeTimestamps<IDBWeddingSupplier>(data))
    .then(sortByCreatedAt);

  // the following is a fallback for missing ids whenever Weddings/Suppliers collection is not updated properly
  // this happens whenever we send enquiries while the shortlist is at max capacity
  const retrievedIds = baseEnquired.reduce<Record<string, string>>((acc, supplier) => {
    acc[supplier.id] = supplier.id;
    return acc;
  }, {});
  let extraEnquired: IWeddingSupplier[] = [];
  if (Object.keys(retrievedIds).length !== supplierIds.length) {
    const missingIds = supplierIds.filter((supplierId) => !(supplierId in retrievedIds));
    extraEnquired = await getSuppliersData(missingIds)
      //@ts-expect-error
      .then(mapSlug)
      .then((data) => mapSerializeTimestamps<IDBWeddingSupplier>(data))
      .then(sortByCreatedAt);
  }

  const enquired: IWeddingSupplier[] = [...baseEnquired, ...extraEnquired];

  if (!values(enquired).length) {
    return {};
  }

  const nonCustom = values<IWeddingSupplier[], number>(enquired).filter((s) => !s.customSupplier);

  return enrichSuppliers(nonCustom).then((list) => {
    const idToIndexNonCustom = new Map(nonCustom.map((s, idx) => [s.id, idx]));
    return batchToRecord(
      [
        ...list.map((item) => {
          const index = idToIndexNonCustom.get(item.id);
          return {
            ...item,
            // @ts-expect-error
            updatedAt: typeof index === 'number' ? nonCustom[index].updatedAt?.seconds : undefined,
          };
        }),
      ],
      enquired,
    ) as Record<string, IUISupplier>;
  });
};

export const getSupplierIdList = (
  item: Record<string, IUISupplier>,
  type: Slug = 'venue',
): string[] =>
  map<IUISupplier, string>(
    (item) => item.id,
    filter<IUISupplier>(
      (item) => item.type === type,
      values<Record<string, IUISupplier>, string>(item),
    ),
  );

export const getIsSupplierContacted = (
  enquiries: IUserEnquiries,
  supplier: Pick<IWeddingSupplier, 'id'>,
) => (enquiries && supplier ? Object.keys(enquiries).includes(supplier.id) : false);

export const filterSupplierListByContacted = ({
  supplierList,
  contactedList,
  getContacted = false,
}: {
  supplierList: IUISupplier[];
  contactedList: TEnquiryDates;
  getContacted?: boolean;
}) => {
  const enquiryList = contactedList ? Object.keys(contactedList) : [];

  return getContacted
    ? supplierList.filter((item) => enquiryList.includes(item.id))
    : supplierList.filter((item) => !enquiryList.includes(item.id));
};

export const formatDateOfVisit = (dateOfVisit?: string) => {
  if (dateOfVisit && dateOfVisit.length > 0) {
    const vDate = dateOfVisit.split(' ');
    return `${vDate[0]} ${vDate[1]}`;
  }

  return null;
};

/**
 * Returns URL for supplier details drawer
 * @param supplier
 * @param shortlistTab - tab opened in shortlist view behind the details drawer
 */
export const getSupplierDetailsDrawerUrl = <
  T extends {
    id: string;
    type: Slug;
    publicId: string;
    name: string;
    customSupplier?: boolean;
    seoUrlSlug?: string;
  },
>(
  supplier: T,
  shortlistTab: TShortlistTab = 'venues',
) => {
  const name = supplier.seoUrlSlug;
  // A custom supplier doesn't have a publicId so we're using normal id
  const id = supplier.customSupplier ? supplier.id.toLowerCase() : supplier.publicId;
  return UrlHelper.shortlist.supplierDetails(`${name}-${id}`, supplier.type, shortlistTab);
};

/**
 *
 * @param urlId Shortlisted supplier ID from URL, that we assume is in a format of `supplier-name-id` (from `getSupplierDetailsDrawerUrl` function) and that the ID itself does not contain any hyphens.
 * @returns Supplier ID
 */
export const getSupplierIdFromShortlistUrlId = (urlId: string) => urlId.split('-').pop();

/**
 * Open drawer with supplier details in shortlist
 * @param supplier
 */
export const navigateToSupplierDetailsDrawer = <
  T extends Parameters<typeof getSupplierDetailsDrawerUrl>[0],
>(
  supplier: T,
  shortlistTab?: TShortlistTab,
  options?: { scroll?: boolean; shallow?: boolean },
) => {
  const tab = shortlistTab || (supplier.type === 'venue' ? 'venues' : 'suppliers');
  const { href, as } = getSupplierDetailsDrawerUrl<T>(supplier, tab);
  Router.push(href, as, options);
};

/**
 * Helper method that saves supplier in wedding suppliers collection. It can be used to add a new
 * supplier or update an existing one. It also allows to book a supplier.
 */
export const upsertWeddingSupplier = async ({
  supplier,
  weddingId,
  overwrite = {},
}: {
  supplier: ISupplier | IUISupplier | IDBWeddingSupplier;
  weddingId: string;
  overwrite?: Partial<IDBWeddingSupplier>;
}) => {
  const isShortlistedSupplier = (supplier: any): supplier is IDBWeddingSupplier =>
    Boolean(supplier.shortlisted) && !supplier.tier;
  const wedding = Weddings._.getById(weddingId);
  const weddingSupplierRef = wedding.Suppliers.getById(supplier.id);
  const weddingSupplierSnap = await weddingSupplierRef.get();

  /**
   * Handle case when supplier is already in the wedding suppliers collection.
   */
  if (weddingSupplierSnap) {
    await weddingSupplierRef.set(overwrite);
    return wedding;
  }

  /**
   * Handle case when supplier is not in the wedding suppliers collection.
   */
  const weddingSupplier = isShortlistedSupplier(supplier)
    ? { ...supplier, ...overwrite }
    : { ...buildShortlistSupplier(supplier), ...overwrite };

  await wedding.Suppliers.add(weddingSupplier);
  return wedding;
};
